import { ReactNode, useEffect, useRef, useState } from 'react';
import { Box, Container } from '@chakra-ui/react';
import FocusLock from 'react-focus-lock';

import Header from './Header';
import { Global } from '@emotion/react';
import { usePageData } from '../../lib/hooks/PageDataContext';
import { useSize } from '../../lib/hooks/UseSize';

type LayoutProps = {
  children: ReactNode;
};

const Layout = ({ children }: LayoutProps) => {
  const { prefill } = usePageData();
  const [ bgOffset, updateBgOffset ] = useState<number>(prefill?.tag ? 373 : 413);

  const boxRef = useRef<HTMLDivElement>(null!);
  const size = useSize(boxRef);

  useEffect(() => {
    if (size?.bottom) {
      const selectorHeight = document.getElementById('SelectorStep')?.getBoundingClientRect().bottom ?? 0;

      updateBgOffset(selectorHeight + 14);
    }
  }, [size?.bottom]);

  return <>
    <Global styles={`body { --background-color-offset: ${bgOffset}px; }`} />

    <Container maxW="container.sm" px="4" py="6" ref={boxRef}>
      <Header />

      <Box as="main" mt="8" mb="10" pos="relative">
        <FocusLock autoFocus={false}>{children}</FocusLock>
      </Box>
    </Container>
  </>;
};

export default Layout;
