
interface Options extends Omit<RequestInit, 'body'> {
  body?: any;
  useNodeApi?: boolean;
}

export default function axios<T = any> (input: RequestInfo | URL, options: Options = {}): Promise<T> {
  options.credentials = 'include';

  if (typeof input === 'string') {
    if (options.useNodeApi) {
      input = process.env.NEXT_PUBLIC_NODE_API_ENDPOINT_URL + input;
    } else {
      input = process.env.NEXT_PUBLIC_API_ENDPOINT_URL + input;
    }
  }

  if (options.body) {
    options.headers = options.headers || {};

    if (!(options.body instanceof FormData)) {
      options.headers['Content-Type'] = 'application/json';
      options.body = JSON.stringify(options.body);
    }
  }

  return new Promise((resolve, reject) => {
    fetch(input, options)
      .then(response => {
        const contentType = response.headers.get('content-type');
        if (contentType?.includes('application/json')) {
          return response.json();
        } else {
          return response.text();
        }
      })
      .then(data => resolve(data))
      .catch(reject)
  });
}
